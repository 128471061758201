
import React, { Fragment } from 'react'
import './nova-loja.scss'
// import { graphql, useStaticQuery } from 'gatsby'
// import RenderForm from 'gatsby-plugin-cloudofertas'
import FormNovaLoja from '../components/form-nova-loja/form-nova-loja'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import LocatorNovaLoja from '../components/Locator/LocatorNovaLoja.js'
// import CounterNovaLoja from '../components/counter/counter-nova-loja'
import CounterFlip from '../components/black-friday-counter/black-friday-counter'
import CountDown from '../components/counter/counter'
import moment from 'moment/moment'

const LojaPenha = ({ data }) => {
  console.log(data.inauguracaoLoja)
  const verifyStringName = (data, string) => {
    return Number(data) === 1 ? string : string + 's'
  }
  const MainCount = ({ time }) => {
    return (
      <div className='counter'>
        <div>
          <CounterFlip value={time.d}></CounterFlip>
          <div className='text' style={{ color: 'black' }}><b>{verifyStringName(time.d, 'dia')}</b></div>
        </div>
        <div className='mx-3'>
          <CounterFlip value={time.h}></CounterFlip>
          <div className='text' style={{ color: 'black' }}><b>{verifyStringName(time.h, 'hora')}</b></div>
        </div>
        <div className='mr-3'>
          <CounterFlip value={time.m}></CounterFlip>
          <div className='text' style={{ color: 'black' }}><b>{verifyStringName(time.m, 'minuto')}</b></div>
        </div>
        <div>
          <CounterFlip value={time.s}></CounterFlip>
          <div className='text' style={{ color: 'black' }}><b>{verifyStringName(time.s, 'segundo')}</b></div>
        </div>
      </div>
    )
  }
  return (
    <Fragment id="blackleste">
      <div className='fundo-azul-claro mb-lg-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-12'>
              <div className='posicao-imagem-desktop'>
                <GatsbyImage
                  image={data.inauguracaoLoja.image.childImageSharp.gatsbyImageData}
                  alt='Imagem teste covid'
                />
              </div>
            </div>
            <div className='col-lg-6 col-12 text-lg-right text-center'>
              <p style={{ color: '#D9304F', fontSize: '60px' }}><b>{moment(data.inauguracaoLoja.inaugurationDate).format('DD/MM')}</b></p>
              <p className='texto-alo-penha'><b>Alô, {data.inauguracaoLoja.name}</b></p>
              <p className='texto-estamos-chegando'><b>estamos chegando!</b></p>
              <p className='texto-vamos-te-dar'>{data.inauguracaoLoja.cupom_phrase} <a href='#quero' ><button style={{ backgroundColor: '#D9304F', border: 'none', padding: '2px', borderRadius: '10px', color: 'white', fontSize: '25px' }}>eu quero</button></a></p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', zIndex: 1 }} className='posicao-fundo-cinza'>
        <div className='fundo-cinza-cortado-position'>
          <GatsbyImage
            image={data.fundocinzaescuro.childImageSharp.gatsbyImageData}
            alt='Imagem teste covid'
            className='mb-5 fundo-cinza-cortado'
          />
        </div>
      </div>
      <div className='container d-block'>
        <div style={{ position: 'relative', zIndex: '3' }}>
          <div className='fundo-azul-escuro'>
            <div className='row'>
              <div className='col-lg-7 col-12'>
                <p className='texto-faltam-contador'>Faltam:</p>
                <CountDown Component={MainCount} date={moment(data.inauguracaoLoja.inaugurationDate).format('MMMM DD, YYYY HH:mm:ss')} />
              </div>
              <div className='col-lg-5 col-12'>
                <GatsbyImage
                  image={data.loja.childImageSharp.gatsbyImageData}
                  alt='Imagem teste covid'
                  className='imagem-loja-icone'
                />
              </div>
            </div>
          </div>
        </div>
        <p className='mt-3 endereco-texto' style={{ position: 'relative', zIndex: 3 }}>{data.inauguracaoLoja.address} - {data.inauguracaoLoja.district} <a href='#comochegar'><button className='texto-como-chegar-vermelho' style={{ border: 'none' }}>como chegar?</button></a></p>
        <div className='text-center ajuste-texto-confira-ofertas'>
          <div className='d-lg-flex justify-content-center'>
            <p><b>Confira nossas ofertas de inauguração</b></p>
            <GatsbyImage
              image={data.icon.childImageSharp.gatsbyImageData}
              alt='Imagem teste covid'
              className='icon-ofertas-nova-loja'
            />
          </div>
          {/* Fotos Ofertas */}
          <div className='row posicao-fotos-ofertas'>
            <div className='col-lg-2 col-6 leiteninho'>
              <a href={data.inauguracaoLoja.offer_url} style={{ textDecoration: 'none' }}>
                <div className='d-block'>
                  <GatsbyImage
                    image={data.leiteninho1.childImageSharp.gatsbyImageData}
                    alt='Imagem teste covid'
                    className='ajuste-leite-ninho'
                  />
                  <p className='text-center mt-3 infantil' style={{ color: 'black' }}>Infantil</p>
                </div>
              </a>
            </div>
            <div className='col-lg-2 col-6'>
              <a href={data.inauguracaoLoja.offer_url} style={{ textDecoration: 'none' }}>
                <div className='d-block'>
                  <GatsbyImage
                    image={data.shampoo.childImageSharp.gatsbyImageData}
                    alt='Imagem teste covid'
                    className='ajuste-shampoo'
                  />
                  <p className='text-center mt-3 infantil' style={{ color: 'black' }}>Beleza</p>
                </div>
              </a>
            </div>
            <div className='col-lg-2 col-6'>
              <a href={data.inauguracaoLoja.offer_url} style={{ textDecoration: 'none' }}>
                <div className='d-block'>
                  <GatsbyImage
                    image={data.pastadedente.childImageSharp.gatsbyImageData}
                    alt='Imagem teste covid'
                    className='ajuste-pasta-dente'
                  />
                  <p className='text-center mt-3 infantil' style={{ color: 'black' }}>Higiene Pessoal</p>
                </div>
              </a>
            </div>
            <div className='col-lg-2 col-6 sustagem'>
              <a href={data.inauguracaoLoja.offer_url} style={{ textDecoration: 'none' }}>
                <div className='d-block'>
                  <GatsbyImage
                    image={data.sustagem.childImageSharp.gatsbyImageData}
                    alt='Imagem teste covid'
                    className='ajuste-sustagem'
                  />
                  <p className='text-center mt-3 infantil' style={{ color: 'black' }}>Sênior</p>
                </div>
              </a>
            </div>
            <div className='col-lg-2 col-6 remedio'>
              <a href={data.inauguracaoLoja.offer_url} style={{ textDecoration: 'none' }}>
                <div className='d-block'>
                  <GatsbyImage
                    image={data.remedio.childImageSharp.gatsbyImageData}
                    alt='Imagem teste covid'
                    className='ajuste-remedio'
                  />
                  <p className='text-center mt-3 infantil' style={{ color: 'black' }}>Medicamentos</p>
                </div>
              </a>
            </div>
            <div className='col-lg-2 col-6 lavitan'>
              <a href={data.inauguracaoLoja.offer_url} style={{ textDecoration: 'none' }}>
                <div className='d-block'>
                  <GatsbyImage
                    image={data.lavitan.childImageSharp.gatsbyImageData}
                    alt='Imagem teste covid'
                    className='ajuste-lavitan'
                  />
                  <div className='texto-vitaminas'>
                    <p className='text-center mt-3 infantil' style={{ color: 'black' }}>Vitaminas</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div >
      <div className='fundo-cinza-claro' id='quero'>
        <div className='container'>
          <p className='text-center' style={{ color: '#D9304F', fontSize: '40px' }}><b>{data.inauguracaoLoja.cupom_phrase}</b></p>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              {/* <div className='d-block' > */}
              <div className='d-flex align-items-center'>
                <div style={{ backgroundColor: '#03588C', borderRadius: '80px', width: '15%', height: '45px', paddingTop: '1px' }} className='mr-2'>
                  <p className='text-center align-middle' style={{ color: 'white', fontSize: '30px' }}>1</p>
                </div>
                <div style={{ height: '5px', width: '100%' }} className='d-flex align-items-center'>
                  <div style={{ height: '1px', backgroundColor: '#03588C', width: '37.5%' }}></div>
                </div>
              </div>
              <p className='text-left' style={{ fontSize: '30px' }}>{data.inauguracaoLoja.step_one}</p>
              {/* </div> */}
            </div>
            <div className='col-lg-4 col-12'>
              <div className='d-flex align-items-center'>
                <div style={{ backgroundColor: '#03588C', borderRadius: '80px', width: '15%', height: '45px', paddingTop: '1px' }} className='mr-2'>
                  <p className='text-center align-middle' style={{ color: 'white', fontSize: '30px' }}>2</p>
                </div>
                <div style={{ height: '5px', width: '100%' }} className='d-flex align-items-center'>
                  <div style={{ height: '1px', backgroundColor: '#03588C', width: '80%' }}></div>
                </div>
              </div>
              <p className='text-left' style={{ fontSize: '30px' }}>{data.inauguracaoLoja.step_two}</p>
            </div>
            <div className='col-lg-4 col-12'>
              <div className='d-flex align-items-center'>
                <div style={{ backgroundColor: '#03588C', borderRadius: '80px', width: '15%', height: '45px', paddingTop: '1px' }} className='mr-2'>
                  <p className='text-center align-middle' style={{ color: 'white', fontSize: '30px' }}>3</p>
                </div>
                <div style={{ height: '5px', width: '100%' }} className='d-flex align-items-center'>
                  <div style={{ height: '1px', backgroundColor: '#03588C', width: '80%' }}></div>
                </div>
              </div>
              <p className='text-left' style={{ fontSize: '30px' }}>{data.inauguracaoLoja.step_three}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-7 col-12'>
              <GatsbyImage
                image={data.mulhercomcelular.childImageSharp.gatsbyImageData}
                alt='Imagem teste covid'
                className='mulher-com-celular'
              />
            </div>
            <div className='col-lg-5 position-relative col-12 mt-5'>
              <FormNovaLoja loja={data.inauguracaoLoja.name} />
              <div>
                <p className='text-center mt-2 texto-mobile-final-cinza'>{data.inauguracaoLoja.observation}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative', alignItems: 'center' }} className='d-flex justify-content-center'>
        <div className='localizacao-endereco'>
          <p className='texto-endereco'>{data.inauguracaoLoja.address}  - {data.inauguracaoLoja.district}</p>
        </div>
      </div>
      <div id='comochegar'>
        <LocatorNovaLoja data={data.inauguracaoLoja} />
      </div>
    </Fragment >
  )
}
export default LojaPenha
export const query = graphql`
query($slug: String!) {
  lojadestaque: file(relativePath: {eq: "images/novaloja/loja-destaque.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  leiteninho1: file(relativePath: {eq: "images/novaloja/leiteninho1.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  shampoo: file(relativePath: {eq: "images/novaloja/shampoo.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  pastadedente: file(relativePath: {eq: "images/novaloja/pastadedente.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  pastadedente1: file(relativePath: {eq: "images/novaloja/pastadedente1.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  sustagem: file(relativePath: {eq: "images/novaloja/sustagem.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  remedio: file(relativePath: {eq: "images/novaloja/remedio.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  lavitan: file(relativePath: {eq: "images/novaloja/lavitan.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  mulhercomcelular: file(relativePath: {eq: "images/novaloja/mulhercomcelular.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  icon: file(relativePath: {eq: "images/novaloja/icon.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  fundocinzaescuro: file(relativePath: {eq: "images/novaloja/fundo-cinza-escuro.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  loja: file(relativePath: {eq: "images/novaloja/loja.png"}){
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  inauguracaoLoja: cloudOfertasInauguracaoLoja(slug: { eq: $slug }) {
      id
      address
      cep
      city
      offer_url
      step_one
      step_two
      step_three
      cupom_phrase
      district
      flg_deleted
      inaugurationDate
      image {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      lat
      lng
      name
      slug
      status_inauguration
      observation
      uf
  }
}
`
